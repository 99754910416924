.ProjectLog {
  a.anchor {
    display: block;
    position: relative;
    top: -70px;
    visibility: hidden;
  }

  .nav {
    flex-wrap: nowrap;

    .nav-item {
      white-space: nowrap;

      a.nav-link {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.btn {
        padding: 0;
        a.nav-link {

        }
        &:hover {
          a.nav-link {
            color: #fff
          }
        }
      }
    }
  }

}