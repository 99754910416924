body[data-layout-color=dark],
body[data-layout-color=light]{
  .crew-attendance-overview {
    .fc .fc-header-toolbar {
      top: 70px !important;
    }
    .fc .fc-scrollgrid-section-sticky > *{
      top: 117px !important;
    }

    .fc-event-main {
      height: 34px !important;
      padding: 9px;
    }
  }
}