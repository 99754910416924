.KitchenMonitor {
  .table-responsive {
    position: relative;

    table {
      tr {
        > * {
          text-align: center;

          &:first-child {
            position: sticky;
            left: 0;
            background-color: var(--ct-card-bg) !important;
            text-align: left;
          }
        }
      }
    }
  }
}