//append($fc-event-colors, "secondary":  $secondary)

$fc-event-colors: map-merge($fc-event-colors, ("none":  $secondary));

@each $color, $value in $fc-event-colors {
  .bg-#{$color} {
    color: shade-color($value, 5%);
    background-color: rgba($value, .18);
    border: 1px solid rgba($value, 0.25);
    .card-link {
      color: shade-color($value, 30%);
    }
  }
}
body[data-layout-color="dark"] {
  @each $color, $value in $fc-event-colors {
    .bg-#{$color} {
      color: tint-color($value, 20%);
    }
  }
}