//
// color select
//

.color-select {
  > div {
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg !important;

    &:hover {
      border: $input-border-width solid $input-border-color;
    }
  }
}

.color-select__value-container {
  text-align: center;
  caret-color: transparent;
  border-radius: $dropdown-border-radius !important;
  margin-left: 1px;
  margin-bottom: 0.5px;
}

.color-select__control--is-focused {
  box-shadow: none !important;
}

.color-select__indicator-separator {
  display: none !important;
}

.color-select__dropdown-indicator {
  color: #{map-get($grays, "600")} !important;
}

.color-select__menu {
  background-color: $dropdown-bg !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: $dropdown-border-radius !important;
  z-index: $zindex-dropdown !important;
  margin: 0 !important;
}

.color-select__menu-list {
  border: $dropdown-border-width solid transparent !important;
  border-radius: $dropdown-border-radius !important;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

}

.color-select__option {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: $dropdown-border-radius !important;
}

.color-select__multi-value {
  background-color: rgba($primary, 0.2) !important;
  color: $primary !important;

  .color-select__multi-value__label {
    color: $primary;
  }

  .color-select__multi-value__remove {
    &:hover {
      background-color: rgba($primary, 0.2) !important;
      color: $primary !important;
    }
  }
}

