body[data-layout-color="dark"] {
  .DateTimePicker {
    .rdtPicker {
      background-color: var(--ct-input-bg);
      border-color: var(--ct-input-border-color);

      thead {
        tr {
          th:hover {
            background-color: var(--ct-link-hover-color) !important;
          }
        }
      }

      tbody {
        td:hover {
          background-color: var(--ct-link-hover-color) !important;
        }
      }
    }
  }
}