
$amber:           #F5BC00;
$jade:            #0EAD69;
$forest_green:    #498b39;
$periwinkle:      #9799FF;
$ultra_violet:    #5353A2;
$mauve:           #C9188E;
$maroon:          #850000;


$colors: (
        "jade":            $jade,
        "forest_green":    $forest_green,
        "periwinkle":      $periwinkle,
        "ultra_violet":    $ultra_violet,
        "mauve":           $mauve,
        "maroon":          $maroon,
        "amber":           $amber,
);

.navbar-custom {
  @each $name, $color in $colors {
    .colored-header-#{$name} {
      background-color: $color;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      opacity: 0.8;
    }
  }
}

body[data-layout-color="light"] {
  .navbar-custom {
    .topbar-menu {
      .nav-link {
        color: #{map-get($grays, "800")};
        &.show {
          color: #{map-get($grays, "900")};
        }
        select.form-select {
          background-color: rgba(0,0,0,0.152);
          border-color: rgba(0,0,0,0.115);
          color: #{map-get($grays, "800")};
        }
      }
    }
  }
  .nav-user {
    background-color: rgba(0,0,0,0.061);
    border: $border-width solid rgba(255,255,255,0.033);
  }
}

body[data-layout-color="dark"] {
  .navbar-custom {
    .topbar-menu {
      .nav-link {
        select.form-select {
          background-color: rgba(255,255,255,0.029);
          border-color: rgba(255,255,255,0.051);
        }
      }
    }
  }
  .nav-user {
    background-color: rgba(255,255,255,0.061);
    border: $border-width solid rgba(0,0,0,0.079);
  }
}